import React from "react";
import { navigate } from "gatsby";

import { SuccessLayoutWrapper } from "../components";

const RegisterSuccess = () => {
  return (
    <SuccessLayoutWrapper
      title="Account successfully created"
      subTitle="Your account has been created. You can now log in with the registered email address."
    >
      <button
        className="reset-success__button"
        onClick={() => navigate("/login")}
      >
        Login to your account
      </button>
    </SuccessLayoutWrapper>
  );
};

export default RegisterSuccess;
